import React from "react";
import Layout from '../components/layout';

export default () => (
  <Layout>
  <div style={{ color: `teal` }}>
    <h1>About me</h1>
    <p>Such wow. Very React.</p>
  </div>
  </Layout>
)